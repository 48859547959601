import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/alumni/Heading';
import ClassNotes, {
  Props as ClassNotesProps
} from 'components/alumni/ClassNotes';
import Memoriam from 'components/alumni/Memoriam';
import Events, { Props as EventsProps } from 'components/alumni/Events';

interface Props {
  data: ClassNotesProps &
    EventsProps & {
      sheltonWilliams: Core.ImageAttributes;
      sheltonAndGregWilliams: Core.ImageAttributes;
      joelMontague: Core.ImageAttributes;
      maryamMontague: Core.ImageAttributes;
      maryamMontagueCamels: Core.ImageAttributes;
      kathrynKnowles: Core.ImageAttributes;
    };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    classNotes,
    events,
    sheltonWilliams,
    sheltonAndGregWilliams,
    joelMontague,
    maryamMontague,
    maryamMontagueCamels,
    kathrynKnowles
  } = props.data;

  return (
    <Layout departmentSlug="alumni" title="Alumni">
      <Heading title="Meet two parent/child alumni duos whose SAIS connections run deep...Read about noteworthy events in the lives of former classmates...Catch up on SAIS alumni events unfolding around the globe." />

      <Core.ContentWrapper>
        <ClassNotes classNotes={classNotes} />

        <Core.HorizontalRule />

        <Core.ContentSection id="sais-types">
          <Core.Flag>Alumni Profile</Core.Flag>
          <Core.PrimaryHeading>&lsquo;SAIS Types&rsquo;</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>From the time he was a child</Core.LeadIn>,
            Texas-raised Greg Williams ’93 would spend two weeks each summer at
            SAIS, at the Summer Symposium on Foreign Policy that his dad,
            Shelton Williams, PhD ’71, launched in 1981.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={sheltonWilliams}
                alt="Shelton Williams portrait"
                contentSectionPlacement="marginalia"
              />
              <Core.Blockquote color="green">
                A ‘SAIS type’ is someone who has studied international economics
                and diplomatic history, with a firm grounding in U.S. national
                security.
              </Core.Blockquote>
            </>
          }
          marginaliaStackedBottom
          forceIndentation
        >
          <p>
            “I started coming when I was too young to attend any of the
            sessions. Then, I monitored the sessions. Then I was a participant.
            Then I was an instructor. Now I am on the board,” says Greg, who
            notes that those early experiences at SAIS were formative. “I think
            I had chosen SAIS for graduate school before I had chosen my
            college,” he says, chuckling.
          </p>
          <p>
            The summer symposium is just one program of the Osgood Center for
            International Studies, which Shelton Williams founded in 2006,
            around the time he left the political science faculty of Austin
            College in Texas. The program offers short-term foreign policy
            programs, internships, and experiential learning to students at all
            stages—from high school through college and graduate school.
            Originally hosted at SAIS, the nonprofit educational center is now
            housed at 1629 K St. NW.
          </p>
          <p>
            “The programs and seminars I run at the Osgood Center are designed
            to help prepare students for professional schools of international
            studies, and we have students coming throughout the year from all
            over the world,” says Shelton. “We’ve sent students on to all the
            big international affairs schools, and I am particularly proud that
            we’ve sent a number of students to SAIS,” he adds.
          </p>
          <p>
            The Osgood Center is named for strategic studies expert Robert E.
            Osgood, who had been Shelton’s PhD mentor and is widely credited
            with developing the “
            <a href="/feature/on-the-shoulders-of-giants">SAIS approach</a>”.
            “As a teacher, I was so inspired by Robert Osgood,” says Shelton,
            who served as dean of social sciences and director of the Posey
            Leadership Institute at Austin College before joining the faculty of
            Norwich University in 2007.
          </p>

          <p>
            An expert in nuclear proliferation, Shelton also did four stints in
            government service throughout his career, including one as a policy
            analyst for the Carter administration and another for the Clinton
            administration, when he worked closely with Madeleine Albright at
            the United Nations.
          </p>
          <p>
            While son Greg followed in his father’s footsteps to SAIS, he chose
            the field of law in which to make his mark.
          </p>
          <p>
            “I am primarily a commercial litigator focusing on international
            litigation and arbitration matters,” says Greg, a lawyer with Wiley
            Rein in Washington, D.C. “My SAIS background has really helped
            inform the substance of what I do.”
          </p>
          <p>
            In one recent big case, for example, he successfully represented
            defense contractor BAE, which had been sued by the Republic of
            Korea’s government for alleged “bad acts.” Essentially, the South
            Koreans were unhappy with BAE’s pricing for upgrading South Korea’s
            aging fleet of F-16 fighter jets. Greg filed a lawsuit in U.S.
            court, arguing that under the United States’ existing national
            security program, such pricing is set by the U.S. government, not
            the contractor. Thus, South Korea’s dispute was misplaced: BAE
            should not be held responsible.
          </p>
          <Core.FullWidthImage
            image={sheltonAndGregWilliams}
            alt="Shelton and Greg Williams"
            caption="Last spring, father and son Shelton and Greg Williams both
              celebrated their class reunions at SAIS."
          />
          <p>
            As the case was playing out, President Trump was working to improve
            relations with South Korea. The Department of Justice “submitted an
            amicus brief arguing that I was wrong on the national security
            issue,” says Greg. “Some 99 percent of the time, the courts will
            defer to the executive branch on matters of national security, like
            this one.” But not this time. “Ultimately, the court disregarded the
            amicus brief,” he says. “It was a pretty good day.”
          </p>
          <p>
            While it’s not unusual for Greg Williams to put in long days at
            Wiley Rein, he says he couldn’t be happier. “My work is an
            interesting blend of litigation, which I love to do, and issues of
            foreign policy and national security, which I learned so much about
            at SAIS,” he says.
          </p>
          <p>
            These days, his dad remains active with the Osgood Center—“We were
            recently picked as best in Washington, D.C., for education and
            training,” Shelton says proudly—and is also a published fiction
            writer on crime and society, with three published books under his
            belt and one nearing completion.
          </p>
          <p>
            Shelton Williams’ fealty to SAIS remains strong, as evidenced by his
            email address:{' '}
            <a href="mailto:saistype@aol.com">saistype@aol.com</a>.
          </p>
          <p>
            “A ‘SAIS type’ is someone who has studied international economics
            and diplomatic history, with a firm grounding in U.S. national
            security,” he says. “Today more than ever, I think it’s critically
            important to have a ‘SAIS type’ of education.”
          </p>
        </Core.ContentSection>
        <Core.SignOff>Sue DePasquale</Core.SignOff>
        <Core.Share id="sais-types" />

        <Core.HorizontalRule />

        <Core.ContentSection
          id="humanitarian-heritage"
          marginalia={
            <>
              <Core.FullWidthImage
                image={maryamMontague}
                alt="Maryam Montague in classroom"
                contentSectionPlacement="marginalia"
              />
              <Core.Blockquote color="green">
                Maryam Montague ’91 is largely focused on advancing the work of
                Project Soar, a nonprofit organization that empowers teen girls
                in the poor, semirural areas of Morocco.
              </Core.Blockquote>
            </>
          }
        >
          <Core.Flag>Alumni Profile</Core.Flag>
          <Core.PrimaryHeading>Humanitarian Heritage</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>“I grew up with a dad</Core.LeadIn> who was very
            different from other dads,” says Maryam Montague ’91, in a telephone
            interview from her home in Morocco.
          </p>
          <p>
            After spending the first 10 years of her life in the Middle East and
            Egypt, her family settled back in the United States. “While other
            dads would head off to work in their suits, my dad would get on a
            plane and be gone for weeks at a time. When he came back, he brought
            his journeys home with him, sharing his ideas about rural
            development and what Americans could do to promote it. I grew up
            fascinated by the concept that the world is this big, amazing place
            that you can easily access.”
          </p>

          <p>
            Now 86, Joel Montague ’60 has spent much of his life working in
            community development and public health in the Middle East, Africa,
            and Asia. His efforts have included everything from helping to build
            roads and schools in Iran, to working on primary care for the Aga
            Khan Health Services in five countries, to collaborating on the
            initiation of birth spacing and malaria control programs in
            Cambodia. He has been twice decorated in foreign countries (Iran and
            Tunisia).
          </p>
          <p>
            “In the case of both decorations, it was a complete surprise,” he
            says. “I was just another expat doing his job, but I was lucky
            enough to wind up working on projects that were novel and useful.”
          </p>
          <p>
            About two decades ago, Joel Montague helped found Partners for
            Development, an international nongovernment organization that
            partners with community organizations abroad to improve the lot of
            the less fortunate in difficult places. Having served for years on
            the board of directors and as board chairman, he now works on the
            organization’s advisory board and continues his travels—most
            recently to Cambodia as a volunteer to work at the field level in
            malaria prevention and control. He’s also doing research (some
            published in books) on the country’s colonial history.
          </p>
          <p>
            Joel is proud of his family: wife, Shahnaz, who maintains her
            practice as a physician; son, Jahan, also a physician; and daughter,
            Maryam, whose 25-year career in international development has
            included humanitarian aid work for the National Democratic
            Institute, where she trained women to run for public office and
            focused on prisoners’ rights issues.
          </p>
          <p>
            These days, Maryam Montague is largely focused on advancing the work
            of Project Soar, a nonprofit organization she launched in 2013 that
            empowers teen girls in the poor, semirural areas of Morocco, where
            many girls drop out of school when they hit puberty to get married
            and raise a family.
          </p>
        </Core.ContentSection>
        <Core.FullWidthImage
          image={maryamMontagueCamels}
          alt="Maryam Montague with camels"
        />
        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={joelMontague}
                alt="Joel Monague potratit"
                contentSectionPlacement="marginalia"
                caption="Joel Montague ’60 has spent much of his life working in
                  community development and public health in the Middle East,
                  Africa, and Asia."
              />
            </>
          }
        >
          <p>
            “The mission of Project Soar is to train girls to be feminist
            leaders of today and tomorrow,” explains Maryam. Participants
            complete a 50-hour girl-centered leadership curriculum that Project
            Soar developed—all for free, provided they pledge to stay in school.
          </p>
          <p>
            “Unlike the multimillion-dollar programs I directed over the years,
            Project Soar literally started ‘in my backyard,’ around my dining
            room table. I got to know the girls and their stories intimately.
            That’s very rewarding,” says Maryam. A self-described “social
            entrepreneur,” she helps fund the program from the boutique hotel,
            Peacock Pavilions, that she and her husband constructed on an olive
            grove in Douar Laadam, near Marrakech.
          </p>
          <p>
            Funding also comes from donors, with a give-back from a new fashion
            line that Maryam recently created. Dubbed Agent Girlpower, it
            includes athleisure wear and jewelry inscribed with feminist
            messages in English and Arabic.
          </p>
          <p>
            While Project Soar started with families who live in Maryam’s
            village (where 73 percent of Project Soar girls now pass an exam
            required to move on to high school, compared to the average passing
            rate of 44 percent for girls in the Marrakech region), it’s rapidly
            expanding. Project Soar is now in 30 sites throughout Morocco,
            empowering more than 900 girls with the help of 70-plus trained
            empowerment facilitators.
          </p>
          <p>
            Maryam has a 10-country plan to empower girls in the Middle East and
            Africa. Her motivation is pragmatic. As she told{' '}
            <em>The New York Times</em> in August 2018, “No country can get
            ahead if it leaves 50 percent of its population behind. The future
            must be powered by strong women.”
          </p>
          <p>
            Her 17-year-old daughter “is a Project Soar girl and very much a
            feminist herself,” says Maryam, noting that Skylar Montague is now
            enrolled in the African Leadership Academy, “which is training
            Africa’s next generation of ethical leaders.”
          </p>
          <p>
            Looking back at her own educational path, Maryam says she will
            remain forever grateful to her dad for introducing her to SAIS.
          </p>
          <p>
            “He always spoke so glowingly of his time at SAIS, and I really
            wanted to be a part of that,” she says. “The economic underpinning I
            got from SAIS is something I continue to use all the time, and my
            SAIS education has provided me an important foundation for operating
            in today’s world.”
          </p>
          <Core.SignOff>Sue DePasquale</Core.SignOff>
          <Core.Share id="humanitarian-heritage" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Events events={events} />

        <Core.HorizontalRule />

        <Memoriam>
          <Core.ContentSection id="memoriam">
            <Core.PrimaryHeading subHeading="April 27, 1976 &mdash; September 18, 2018">
              Remembering Kathryn Knowles B’01, ’02
            </Core.PrimaryHeading>
            <p>
              <Core.LeadIn>Santiago.</Core.LeadIn>If I were going to summarize
              Kathryn’s life in one word, that would be it. The only child of
              her parents, Carol and Tony, she was one of those kids who always
              seems to wind up being club president. Hardworking and whip smart,
              she was an outstanding student who clearly enjoyed her university
              experience at Stanford. But Santiago is what changed her. She
              spent time during junior year in Chile. She loved the new culture.
              She loved speaking Spanish and living with a family. Most of all,
              she loved the adventure. Kathryn was a success by all the
              conventional standards, but deep down inside, she was most
              passionate about exploring the unconventional opportunities that
              life has to offer.
            </p>
            <Core.FullWidthImage
              image={kathrynKnowles}
              alt="Kathryn Knowles portrait"
            />
            <p>
              When she left college, Kathryn followed many of her Stanford
              classmates into the technology world. It was the late 1990s. The
              tech boom was in full swing. Her job was to recruit the best and
              the brightest from America’s top universities to create the next
              “unicorn.”
            </p>
            <p>
              Kathryn left the tech world behind to go to SAIS instead. Again,
              she chose adventure. Bologna was the new Santiago. She threw
              herself into it. She met Marco and joined a new family.
            </p>
            <p>
              When Kathryn graduated from SAIS, she came back to Bologna to
              settle. The challenge was combining her talents, her interests,
              and her determination to find a work-life balance. Most of us
              cannot imagine squeezing in so much life before and after such a
              long workday. Along the way, she carved out a career that combined
              support for a major international cancer research foundation, the
              Istituto Ramazzini, and business development for BolognaFiere.
            </p>
            <p>
              She came back to SAIS in 2011 as director of public affairs and
              assistant director of the European Studies Program. The next seven
              years were a whirlwind of activity. Kathryn had a knack for taking
              charge of complex situations and making them work out smoothly. At
              times, this ruffled feathers, but she never shied away, and she
              was fiercely protective. The students loved her for it.
            </p>
          </Core.ContentSection>
          <Core.ContentSection
            marginalia={
              <Core.Caption>
                <em>
                  To honor Kathryn Knowles’ contribution to SAIS, we have
                  launched the <strong>Kathryn Knowles Internship Fund</strong>,
                  ensuring that students in the European and Eurasian Studies
                  Program or attending SAIS Europe have access to internships
                  and study trips.{' '}
                  <a
                    href="https://europe.jhu.edu/support-the-bc/memorial-initiatives/kknowles.cfm?CFID=6073941&CFTOKEN=211ee8cd6c0728fe-183075E6-AD63-B407-9F36FA6151B87B4A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Lend your support</strong>
                  </a>
                  .
                </em>
              </Core.Caption>
            }
            marginaliaStackedBottom
            forceIndentation
          >
            <p>
              Although Kathryn enjoyed many aspects of SAIS, her favorite was
              counseling students on their courses, helping them find
              internships, taking them on trips, and guiding them toward
              unconventional choices. She wanted everyone to have their own
              Santiago moment, where they could appreciate the difference
              between “success” and adventure.
            </p>
            <p>
              The challenge, Kathryn insisted, was to strike the right balance
              among success and adventure, work, and life. During her time at
              SAIS, she and Marco had their son, Jackson, and she continued to
              deepen and expand her circle of friendships on both sides of the
              Atlantic. In short, Kathryn lived her own advice.
            </p>
          </Core.ContentSection>
          <Core.ContentSection>
            <Core.SignOff>Erik Jones</Core.SignOff>
            <Core.Share id="memoriam" />
          </Core.ContentSection>
        </Memoriam>
        <Core.MoreResources>
          To see upcoming global alumni events, visit{' '}
          <a
            href="http://www.sais-jhu.edu/atoz/alumni-news-events#events"
            target="_blank"
            rel="noopener noreferrer"
          >
            sais-jhu.edu/atoz/alumni-news-events
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    classNotes: allFile(
      filter: {
        relativePath: { regex: $directory }
        name: { eq: "class-notes" }
      }
    ) {
      ...ClassNotes
    }
    events: allFile(
      filter: { relativePath: { regex: $directory }, name: { eq: "events" } }
    ) {
      ...Events
    }
    sheltonWilliams: file(
      relativePath: { regex: $directory }
      name: { eq: "shelton-williams" }
    ) {
      ...MarginaliaImage
    }
    sheltonAndGregWilliams: file(
      relativePath: { regex: $directory }
      name: { eq: "shelton-and-greg-williams" }
    ) {
      ...ContentWidthImage
    }
    joelMontague: file(
      relativePath: { regex: $directory }
      name: { eq: "joel-montague" }
    ) {
      ...MarginaliaImage
    }
    maryamMontague: file(
      relativePath: { regex: $directory }
      name: { eq: "maryam-montague" }
    ) {
      ...MarginaliaImage
    }
    maryamMontagueCamels: file(
      relativePath: { regex: $directory }
      name: { eq: "maryam-montague-camels" }
    ) {
      ...FullWidthImage
    }
    kathrynKnowles: file(
      relativePath: { regex: $directory }
      name: { eq: "kathryn-knowles" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
